if (($('.img-text-side').length > 0) || ($('.text-img-side').length > 0)) {
	$('.texture').addClass('lg-texture')
}



var height = .01 * window.innerHeight;
var footerHeight = $("#footer").innerHeight();
$(".page-container").css({
	"min-height": "calc(100vh - " + footerHeight - $(".header.sticky").innerHeight() - 0 + "px)"
})

if ($('.header.sticky').length > 0) {
	$("body").css("paddingTop", $(".header.sticky").innerHeight())
}






//Search Wrapper

if ($('.toggle-serach-bar').length > 0) {
	function searchToggle(obj, evt) {

		var container = $(obj).closest('.search-wrapper');
		if (!container.hasClass('active')) {
			container.addClass('active');
			container.find('.search-input').focus();
			evt.preventDefault();

		} else if (container.hasClass('active') && $(obj).closest('.input-holder').length == 0) {
			container.removeClass('active');
			// clear input
			container.find('.search-input').val('');
		}

	}
	$('.toggle-serach-bar').click(function () {
		searchToggle(this, event);

	})

}


// End Search Wrapper



//for small-header
// $(window).scroll(function () {
// 	var sc = $(window).scrollTop()
// 	if (sc > 0) {

// 		$(".header").addClass("header-small")
// 	} else {

// 		$(".header").removeClass("header-small")
// 	}
// });

//Smart Header Functions
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = '';

navbarHeight = $(".header.sticky").innerHeight();
$("body").css('paddingTop', navbarHeight);
//end Smart Header Functions
$(window).scroll(function () {
	if ($(window).scrollTop() >= 20 + $(
			'.page-container').offset().top + $('.page-container').outerHeight() - window.innerHeight) {

		$('.send-us-msg').css({
			'bottom': $('.footer').innerHeight() + 'px',
			"position": "absolute"
		});
	} else {
		$('.send-us-msg').css({
			'bottom': '10px',
			"position": "fixed"

		});
	}

	//Smart Header Functions

	didScroll = true;

	//end Header Functions



});

//Smart Header Functions
setInterval(function () {
	if (didScroll) {
		geekflare_hasScrolled();
		didScroll = false;
	}
}, 250);

function geekflare_hasScrolled() {
	var st = $(window).scrollTop();
	// Make sure to scroll more than delta
	if (Math.abs(lastScrollTop - st) <= delta)
		return;

	// If scrolled down and are past the navbar
	// This is necessary so you never see what is "behind" the navbar.
	if (st > lastScrollTop && st > navbarHeight) {

		// Scroll Down
		$('.animated .navigation .nav-item,.header.animated .social-icons li').addClass('show koko');

		$('.header.sticky').css('top', -navbarHeight - 20).removeClass('shadow1');
		$('.animated .navigation .nav-item,.header.animated .social-icons li').removeClass('show');

	} else {

		// Scroll Up
		if (st + $(window).height() < $(document).height()) {

			$('.header.sticky').css('top', 0).addClass('shadow1');
			InitMenuAnimation();


		}
	}

	if (st < 15) {
		$('.header.sticky').css('top', 0).removeClass('shadow1');
	}

	lastScrollTop = st;
}


function InitMenuAnimation() {
	$('.header').addClass('animated-header')
	let $menuItems = $('.animated .navigation .nav-item');
	let time = 20;

	$menuItems.each(function (i) {
		setTimeout(function () {
			$('.animated .navigation .nav-item').eq(i).addClass('show');
			// $(this).addClass('lol');
		}, time)
		time += 100;
	});

	//social-icons
	let $SocialItems = $('.header.animated .social-icons li');
	let time2 = 20;
	$menuItems.each(function (i) {
		setTimeout(function () {
			$('.header.animated .social-icons li').eq(i).addClass('show');
			// $(this).addClass('lol');
		}, time2)
		time2 += 75;
	});
}




//end Header Functions
$(document).on('click', function (e) {
	var accordion = $(".send-us-msg .form");
	var collapse = $(".send-us-msg .collapse");

	if (!$(e.target).closest(accordion).length) {
		collapse.collapse('hide');
	}



});


//filter(career)


import mixitup from 'mixitup';

if ($('.filter-Container').length > 0) {
	var $filterSelect = $('#FilterSelect');
	var containerEl = document.querySelector('.filter-Container');

	var mixer = mixitup(containerEl);

	// var mixer = mixitup(containerEl, {
	//     selectors: {
	//         target: '.mix'
	//     },
	//     animation: {
	//         duration: 300
	//     }
	// });


	//$container.mixitup();

	$filterSelect.on('change', function () {
		//var mixer = mixitup(containerEl, this.value);
		var mixer = mixitup(containerEl);

		mixer.filter(this.value);
		console.log(mixer.getState().totalShow); // 4

	});


}








if ($('.more-content').length) {


	//https://codepen.io/flo44781/pen/agZWwo
	$(".more-content .moreless").click(function () {
		var thisEl = $(this);
		var cT = thisEl.closest(".truncate-text");
		var tX = ".truncate-text";

		if (thisEl.hasClass("less")) {
			cT.prev(tX).toggle();
			cT.slideToggle();



			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $(".moreellipses .moreless").offset().top - 200
				}, 500);
			}, 0);


		} else {
			cT.toggle();
			cT.next(tX).fadeToggle();
		}
		return false;
	})
	/* end iffe */




}


//career upload
$('input[type="file"]').on('change', function () {
	//get the file name
	var fileName = $(this).val();
	//replace the "Choose a file" label
	$(this).parent().find('.filename').html(fileName);
})


//custom select
import 'select2';
// globally assign select2 fn to $ element
$('.selectpicker').select2();
