import anime from 'animejs/lib/anime.es.js';

function InitMenuAnimation() {
	$('.header').addClass('animated-header')
	let $menuItems = $('.animated .navigation .nav-item');
	let time = 20;
	$menuItems.each(function (i) {
		setTimeout(function () {
			$('.animated .navigation .nav-item').eq(i).addClass('show');
			// $(this).addClass('lol');
		}, time)
		time += 150;
	});

	//social-icons
	let $SocialItems = $('.header.animated .social-icons li');
	let time2 = 20;
	$menuItems.each(function (i) {
		setTimeout(function () {
			$('.header.animated .social-icons li').eq(i).addClass('show');
			// $(this).addClass('lol');
		}, time2)
		time2 += 150;
	});
}

function InitAnimation() {

	$('.page-container').addClass('loaded');

	function myFunction(x) {
		if (x.matches) {
			// Wrap every word in a span
			//$('.sectionTitle').each(function () {
			$('.sectionTitle').eq(0).each(function () {

				let text = $(this).text();
				let words = text.split(' ');

				// Clear current element
				this.innerHTML = '';

				// Loop through each word, wrap each letter in a span
				for (let word of words) {
					//let word_split = word.replace(/([^\x00-\x80]|\w)/g, "<span class='letter'>$&</span>");
					let word_split = word.replace(/([^\x00-\x80]|\w)/g, "<span class='letter'>$&</span>");


					if (word_split == '&') {

						console.log('word_splitsss', word_split);
						word_split = "<span class='letter'>" + word_split + "</span>";
						console.log('word_split 2', word_split);

					}

					// Wrap another span around each word, add word to header
					this.innerHTML += '<span class="word">' + word_split + '</span>';
				}
			});

			anime.timeline({
					loop: false
				})
				.add({
					//targets: '.sectionTitle .letter',
					targets: '.sectionTitle .letter',
					scale: [0.3, 1],
					opacity: [0, 1],
					translateZ: 0,
					easing: "easeOutExpo",
					duration: 600,
					//delay: (el, i) => 70 * (i + 1),
					delay: (el, i) => 80 * (i + 1)

				})
			// .add({
			// 	targets: '.sectionTitle .letter',
			// 	translateY: [100, 0],
			// 	easing: "easeOutExpo",
			// 	duration: 1400,
			// 	delay: function (el, i) {
			// 		return 30 * i;
			// 	}
			// }).add({
			// 	targets: '.sectionTitle',
			// 	opacity: 0,
			// 	duration: 1000,
			// 	easing: "easeOutExpo",
			// 	delay: 1000
			// });
		}
	}

	function myFunction2(x) {
		if (x.matches) { // If media query matches
			var textWrapper = document.querySelector('.sectionTitle');

			textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
			anime.timeline({
					loop: false
				})
				.add({
					//targets: '.sectionTitle .letter',
					targets: [document.querySelectorAll('.sectionTitle .letter')],
					scale: [0.3, 1],
					opacity: [0, 1],
					translateZ: 0,
					easing: "easeOutExpo",
					duration: 600,
					delay: (el, i) => 70 * (i + 1)
				})
		} else {}
	}

	var x = window.matchMedia("(min-width: 0px)")
	myFunction(x) // Call listener function at run time
	x.addListener(myFunction) // Attach listener function on state changes
	// Wrap every letter in a span


}





var $animation_elements = $('.animation-element ,.animated_items,.anim-img-container,.lg-hotel-img,.home-slider');
var $window = $(window);

function check_if_in_view() {
	var window_height = $window.height();
	var window_top_position = $window.scrollTop();
	//var window_bottom_position = (window_top_position + window_height);
	var window_bottom_position = (window_top_position + window_height - 150);

	$.each($animation_elements, function () {

		var $element = $(this);
		if (($element.hasClass('fast'))) {
			window_bottom_position = (window_top_position + window_height);

		}
		if ($(window).width() < 768) {
			window_bottom_position = (window_top_position + window_height) + 40;

		}
		var element_height = $element.outerHeight();
		var element_top_position = $element.offset().top;
		var element_bottom_position = (element_top_position + element_height);

		//check to see if this current container is within viewport
		if ((element_bottom_position >= window_top_position) &&
			(element_top_position <= window_bottom_position)) {
			$element.addClass('in-view');

			if ($element.hasClass('animated_items')) {


				let $animatedItems = $element.find('.item');

				let time = 0;
				$animatedItems.each(function (i) {
					setTimeout(function () {
						$element.find('.item').eq(i).addClass('show');
						// $(this).addClass('lol');
					}, time)
					time += 150;
				});
			}
		} else {
			if (($element.hasClass('line'))) {
				$element.removeClass('in-view');
			}

		}
	});
}



InitMenuAnimation()
setTimeout(function () {
	$window.on('scroll resize', check_if_in_view);
	$window.trigger('scroll')
	InitAnimation();

}, 100)
