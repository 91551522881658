import Swiper from 'swiper';

// import SwiperAnimation from 'swiper-animation'


setTimeout(function () {
	if ($('.home-slider .swiper-container').length > 0) {
		$('.slogan').addClass('slogan-init');
		let loop = false;
		if ($('.home-slider .swiper-container .swiper-slide').length > 1)
			loop = true;


		const navBar = new Swiper('.home-slider .swiper-container', {
			loop: loop,
			direction: 'vertical',
			speed: 800,

			effect: 'fade',
			pagination: {
				el: '.home-slider .swiper-pagination',
				clickable: true,
			},
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},



			on: {
				slideChangeTransitionStart: function () {
					//	$('.slogan').hide(0);
					$('.slogan').removeClass('slogan-init');
				},
				slideChangeTransitionEnd: function () {
					//	$('.slogan').show(0);
					$('.slogan').addClass('slogan-init');

				},
			}


		})

	}

	//.banners-slide
	if ($('.s-slider .swiper-container').length > 0) {


		$('.s-slider .swiper-container').each(function () {

			var thisSlider = $(this).closest('.s-slider');
			let dynamicBullets = false


			let loop = false;
			if (thisSlider.find('.swiper-container .swiper-slide').length > 1)
				loop = true;




			if (thisSlider.hasClass('has-dynamic-bullets')) {
				dynamicBullets = true
			}
			var MSwiper = new Swiper($(this), {

				spaceBetween: 0,
				loop: loop,
				slidesPerView: 1,
				// effect: 'fade',
				speed: 1000,
				preloadImages: false,
				autoplay: {
					delay: 2500,
				},
				paginationClickable: true,
				lazy: {
					loadPrevNext: true,
				},

				// autoplay: {
				// 	delay: 2500,
				// },

				pagination: {
					el: thisSlider.find('.swiper-pagination'),
					clickable: true,
					dynamicBullets: dynamicBullets,
					dynamicMainBullets: 5,

				},


				navigation: {
					nextEl: thisSlider.find('.nav.prev'),
					prevEl: thisSlider.find('.nav.next'),
				},








			});

			thisSlider.find('.swiper-container').on('mouseenter', function (e) {
				console.log('stop autoplay');
				MSwiper.autoplay.stop();
			})
			thisSlider.find('.swiper-container').on('mouseleave', function (e) {
				console.log('start autoplay');
				MSwiper.autoplay.start();
			})
		});


	}




}, 500)









//logos-slider

$('.logos-slider .swiper-container').each(function () {

	var logos_slider = new Swiper($(this), {
		spaceBetween: 15,

		loop: false,
		preloadImages: false,
		lazy: true,
		slidesPerGroup: 1,

		slidesPerView: 3,
		breakpoints: {
			640: {
				slidesPerView: 2,
				spaceBetween: 4,
				slidesPerGroup: 2,
			},

		},
		navigation: {
			nextEl: $(this).parent().find('.swiper-button-next'),
			prevEl: $(this).parent('').find('.swiper-button-prev')
		},
		pagination: {
			el: $(this).parent('').find('.swiper-pagination'),
			clickable: true,
			// dynamicBullets: true,
		},
	})
	if ($('.logos-slider .swiper-container .swiper-slide .active').length > 0) {
		var active_index = $('.logos-slider .swiper-container .swiper-slide .active').closest('.swiper-slide').index();

		logos_slider.slideTo(active_index);
	}
});


//end logos slider






$('.header-mob .toggle-serach-bar').click(function () {

	$('.header-mob  .search-form').toggleClass('opened');
	$('.header-mob  .search-form').find('.search-input').focus();


})

$('.navbar-toggler').click(function () {
	$('.header,.navigation').toggleClass('opened-navigation');
	$('body').toggleClass('opened-navigation');
	$('.navbar-toggler').toggleClass('collapsed');
	$('.navbar-toggler .animated-icon1').toggleClass('open');

})

//home header fixed when arrive
// var hom_header = $('.main-home-section .navbar.navigation');
// if (hom_header.length > 0) {


// 	var s = hom_header.offset().top //+ hom_header.outerHeight();


// 	function checkHomeHeaderPosition() {
// 		var t = $(window).scrollTop();
// 		s < t ? (hom_header.removeClass("position-absolute").addClass("top-indexed position-fixed"),
// 			$(".navbar").next().css({
// 				marginTop: hom_header.innerHeight()
// 			})) : (hom_header.addClass("position-absolute").removeClass("top-indexed position-fixed"),
// 			hom_header.next().css({
// 				marginTop: "0px"
// 			}))
// 	}
// 	checkHomeHeaderPosition()


// 	$(window).scroll(function () {
// 		checkHomeHeaderPosition();
// 	})
// }

function goto(event) {
	var noHeaderClose = '.header.opened-navigation  *';
	var CloseMobSearch = '.header-mob *';
	var CloseDeskTopSearch = '.search-wrapper ,.search-wrapper *';


	if (!event.target.matches(noHeaderClose)) {
		$('.header,.navigation').removeClass('opened-navigation');
		$('body').removeClass('opened-navigation');
		$('.navbar-toggler').removeClass('collapsed')
		$('.navbar-toggler .animated-icon1').removeClass('open');


	}
	if (!event.target.matches(CloseMobSearch)) {
		$('.header-mob  .search-form').removeClass('opened');



	}
	if (!event.target.matches(CloseDeskTopSearch)) {
		$('.search-wrapper').removeClass('active');



	}
};
document.body.addEventListener('click', goto);
