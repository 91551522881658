/* src/app.js */
/* eslint-disable */

// Styles
// import "../node_modules/bootstrap/scss/bootstrap.scss";
import "./assets/styles/main-ltr.scss";


//Scriptsbootstrap-slider.scss
// import 'bootstrap';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/collapse';

import Swiper from 'swiper';
$(document).ready(() => {
	// require('bootstrap-select/dist/js/bootstrap-select.js');
	require('./assets/scripts/navBar');
	require('./assets/scripts/custom');
	require('./assets/scripts/hotels');

	require('./assets/scripts/animation');
	// require('./assets/scripts/dropdown');
	//require('./assets/scripts/fileinput');
	require('./assets/scripts/e-motion-validate');
	require('./assets/scripts/google_map');
});
