//hotels
var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
jQueryBridget('isotope', Isotope, $);
import Swiper from 'swiper';

$(window).on("load", function () {
	if ($('.grid').length > 0) {
		$('.grid').isotope({
			itemSelector: '.item',
		});

	}
});
if ($('.grid').length > 0) {


	$('.grid').addClass('row').removeClass('card-columns');
	$('.grid').find(".item").addClass('col-6 col-md-4').removeClass('card');


	$('.grid').isotope({
		itemSelector: '.item',
	});


	$('.filter-button-group').on('click', '.button', function () {
		function onAnimationFinished() {

			let p_top = $('.filter-button-group').offset().top - $('.header.sticky').outerHeight();
			if ($(window).outerWidth() < 992) {
				p_top = $('.filter-button-group').offset().top - $('.header.header-mob').outerHeight();
			}
			setTimeout(function () {
				window.scrollTo({
					top: p_top,
					left: 100,
					behavior: 'smooth'
				});
			}, 100);


		};

		var filterValue = $(this).attr('data-filter');
		$('.grid').isotope({
			filter: filterValue
		});
		onAnimationFinished()



		$('.filter-button-group .button').removeClass('active');
		$(this).addClass('active');



	});

}
//hotels img gallery 
let theactive_slide = 0;
//https://codepen.io/svelts/pen/VYxPWW/
$(".grid .item").click(function () {
	$('.GallModal').modal('show');
	var image = $(".GallModal").find('.img-Wrapper img.gall-img');
	let dataFilter = '*';
	theactive_slide = $(this).index();

	if ($('.filters.filter-button-group .button.active').length > 0) {
		dataFilter = $('.filters.filter-button-group .button.active').attr('data-filter');
		console.log('dataFilter', dataFilter)
	}
	let $gridItems = $(".grid .item");
	if (dataFilter != '*') {

		$gridItems = $(".grid .item" + dataFilter);
		theactive_slide = $(this).index(dataFilter);


	}
	let img_src = '';
	let img_alt = '';
	let slide = '';
	let output = '';
	$gridItems.each(function (i) {


		img_src = $gridItems.eq(i).find('img.gall-img').attr('src');
		img_alt = $gridItems.eq(i).find('img.gall-img').attr('alt');
		slide += '<div class="swiper-slide"> <div class="anim-img-container d-inline-block"> <img class="img img-fluid" src="' + img_src + '"/></div></div>'
		$('#GallModal .gallery-slider').css('opacity', '0')
		$('#GallModal  .gall_loader').removeClass('d-none');
	});
	$('#GallModal .gallery-slider .swiper-container').remove();
	output += '<div class="swiper-container w-100">'

	output += '<div class="swiper-pagination w-100 d-md-none d-block"></div>'

	output += '<div class="swiper-wrapper align-items-center">'
	output += slide;
	output += '</div>'

	output += '</div>'
	output += '</div>'

	$('#GallModal .gallery-slider').append(output);






});

$('#GallModal').on('shown.bs.modal', function (e) {
	if ($('.gallery-slider .swiper-container').length > 0) {

		var MSwiper;
		$('#GallModal .gallery-slider .swiper-container').each(function () {

			var thisSlider = $(this).closest('.gallery-slider');
			let dynamicBullets = false


			let loop = false;
			if (thisSlider.find('.swiper-container .swiper-slide').length > 1)
				//loop = true;




				if (thisSlider.hasClass('has-dynamic-bullets')) {
					dynamicBullets = true
				}
			MSwiper = new Swiper($(this), {
				autoHeight: true,
				spaceBetween: 30,
				loop: loop,
				slidesPerView: 1,
				//effect: 'fade',
				speed: 650,
				preloadImages: false,
				// autoplay: {
				// 	delay: 2500,
				// },
				paginationClickable: true,
				lazy: {
					loadPrevNext: true,
				},

				// autoplay: {
				// 	delay: 2500,
				// },

				pagination: {
					el: thisSlider.find('.swiper-pagination'),
					clickable: true,
					dynamicBullets: dynamicBullets,
					dynamicMainBullets: 10,

				},

				on: {
					slideChangeTransitionStart: function () {
						//	$('.slogan').hide(0);
						//$('#GallModal .anim-img-container').removeClass('in-view');
					},
					slideChangeTransitionEnd: function () {
						//	$('.slogan').show(0);
						//	$('#GallModal .anim-img-container').addClass('in-view');

					},
				},
				navigation: {
					nextEl: thisSlider.find('.nav.prev'),
					prevEl: thisSlider.find('.nav.next'),
				},





			});

			MSwiper.slideTo(theactive_slide, 0)

		});
		setTimeout(function () {
			MSwiper.init();
			$('#GallModal .anim-img-container').addClass('in-view');

			$('#GallModal  .gall_loader').addClass('d-none');
			$('#GallModal .gallery-slider').css('opacity', '1')

		}, 100)

	}
})
$('#GallModal').on('hidden.bs.modal', function (e) {
	$(".GallModal").find('.img-Wrapper img.gall-img').attr('src', '').attr('alt', '');
	$(".grid .item.selected").removeClass('selected');

	//$(".GallModal").find('.img-Wrapper img.gall-img').attr('alt', img_alt)
})
// $(document).keyup(function (e) {
// 	if (e.keyCode == 27) {
// 		$(".lightbox").fadeOut(300);
// 		$(".lightbox img").remove();
// 		$("html").css("overflow", "auto");
// 	}
// });



//end hotels
